<template>
  <div class="w-block__pre-heading">
    <!-- pre-heading block -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
    isHomeActive() {
      return this.$route.name === 'index'
    },
  },
  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  justify-content: center;
  text-align: center;
  font-family: Flame !important;
}
.custom-color {
  color: #502314;
}
.subtitle-1 {
  font-weight: bold;
  color: #ffffff;
}
</style>
